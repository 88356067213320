import React from 'react';
import { useSelector } from 'react-redux';
import CalendarV2 from './CalendarEventHoliday';
import AttedanceCalender from './AttedanceCalender';

const TeacherCalendar = () => {
  const systemConfig = useSelector((state) => state.commonFilterReducer?.systemConfig);
  const configOn = systemConfig?.['calendar-v2'];

  return <>{configOn ? <CalendarV2 /> : <AttedanceCalender />}</>;
};

export default TeacherCalendar;
