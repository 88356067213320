import React, { useState, useEffect, useRef, createRef } from 'react';
import Layout from 'containers/Layout';
import {
  Breadcrumb,
  Checkbox,
  Select,
  Input,
  Button,
  message,
  Form,
  Spin,
  DatePicker,
} from 'antd';
import axios from 'axios';
import axiosInstance from 'v2/config/axios';
import endpoints from 'v2/config/endpoints';
import { useSelector } from 'react-redux';
import smallCloseIcon from 'v2/Assets/dashboardIcons/announcementListIcons/smallCloseIcon.svg';
import uploadIcon from 'v2/Assets/dashboardIcons/announcementListIcons/uploadIcon.svg';
import { DownOutlined, CheckOutlined } from '@ant-design/icons';
import '../index.css';
import { X_DTS_HOST } from 'v2/reportApiCustomHost';
import UploadModal from './UploadModal';
import MembersModal from './MembersModal';
import { useHistory } from 'react-router-dom';
import { Profanity } from 'components/file-validation/Profanity.js';
import moment from 'moment';
import { TrackerHandler } from 'v2/MixpanelTracking/Tracker';

const { Option } = Select;

const intimationOptions = [
  'Intimate Via SMS',
  'Intimate Via Email',
  'Intimate Via Whatsapp',
];

const CreateAnnouncement = (props) => {
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const history = useHistory();
  const formRef = useRef(null);
  const branchList = useSelector((state) => state.commonFilterReducer?.branchList);

  const systemConfig = useSelector(
    (state) => state.commonFilterReducer?.systemConfig
  );
  const notiConfig = JSON.parse(systemConfig?.anncmt_cumctn_config?.[0].replace(/'/g, '"').replace(/True/g, 'true'));

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [selectedUserLevels, setSelectedUserLevels] = useState();
  const [branchId, setBranchId] = useState([]);
  const [gradeIds, setGradeIds] = useState([]);
  // const [sctionIds, setSectionIds] = useState([]);
  const [sectionMappingIds, setSectionMappingIds] = useState([]);
  const [membersCount, setMembersCount] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [userLevelList, setUserLevelList] = useState([]);
  const [moduleId, setModuleId] = useState('');
  const [loading, setLoading] = useState(false);
  const NavData = JSON.parse(localStorage.getItem('navigationData')) || {};
  const isStudentIncluded = selectedUserLevels?.includes(13);
  const [allGradesSelected, setAllGradesSelected] = useState(false);
  const [email, setEmail] = useState(false);
  const [sms, setSMS] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [flashEvent, setFlashEvent] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [flashModal, setFlashModal] = useState(false);
  const [flashLink, setFlashLink] = useState(null);
  const [uploadedFlashFiles, setUploadedFlashFiles] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [allowPublish, setAllowPublish] = useState(true);
  const [feeReminderSelected, setFeeReminderSelected] = useState();
  const [dataForEdit, setDataForEdit] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedAcadSession, setSelectedAcadSession] = useState([]);
  const [gradeData, setGradeData] = useState([]);
  const [gradeID, setGradeID] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [sectionIDs, setSectionIDs] = useState([]);
  const [originalUserLevels, setOriginalUserLevels] = useState([]);
  const { is_superuser } = JSON.parse(localStorage.getItem('userDetails')) || {};
  // const is_superuser = true;
  const { user_level } = JSON.parse(localStorage.getItem('userDetails')) || {};
  const sessionYear = JSON.parse(sessionStorage.getItem('selected_branch')) || {};
  // const user_level = 14;

  const { TextArea } = Input;

  const handleUploadModalClose = () => {
    setShowUploadModal(false);
  };
  const handleUploadFlashModalClose = () => {
    setFlashModal(false);
  };

  const handleMembersModalClose = () => {
    setShowMembersModal(false);
  };

  const handleUploadedFiles = (value) => {
    setUploadedFiles(value);
  };
  const handleFlashUploadedFiles = (value) => {
    setUploadedFlashFiles(value);
  };
  const handleRemoveUploadedFile = (index) => {
    const newFileList = uploadedFiles.slice();
    newFileList.splice(index, 1);
    setUploadedFiles(newFileList);
  };

  const handleChange = (value) => {
    setSelectedCategory(value);
    if (!props?.match?.params?.id) {
      if (value == 11) {
        setFeeReminderSelected(true);
        handleUserLevel([13]);
        formRef.current.setFieldsValue({
          user_level: [13],
        });
        if (sectionMappingIds.length > 0) {
          fetchMembersCount({
            role_id: selectedUserLevels.toString(),
            branch_id: selectedBranch.toString(),
            is_allowed_for_all: true,
            section_id: sectionMappingIds.join(','),
            grade_id: gradeIds.join(','),
          });
        }
      } else {
        setFeeReminderSelected(false);
        handleUserLevel([]);
        formRef.current.setFieldsValue({
          user_level: [],
        });
      }
    }
  };

  useEffect(() => {
    fetchUserLevel();
    if (props?.match?.params?.id) {
      getAnnouncementData(props?.match?.params?.id);
    }
  }, []);

  const getAnnouncementData = async (id) => {
    setLoading(true);
    try {
      const [res, userLevels] = await Promise.all([
        axiosInstance.get(
          `${endpoints.createAnnouncementV2.retrieveUpdateDeleteAnnouncement}`,
          {
            params: { announcement_id: id, is_edit: true },
          }
        ),
        fetchUserLevel(),
      ]);

      const data = res.data?.result;
      setDataForEdit(data);
      setSelectedCategory(data?.category);
      setTitle(data?.title);
      setDescription(data?.content);
      setMembersCount(data?.members?.length);
      setUploadedFiles(data?.attachments?.map((item) => [item]));
      const selectedBranchIds = branchList
        .filter((branch) => data.acad_session.includes(branch.id))
        .map((branch) => branch.branch.id);
      setSelectedBranch(selectedBranchIds);
      setSelectedAcadSession(data.acad_session);
      setSelectedUserLevels(data?.role_id);
      setOriginalUserLevels(data?.role_id);
      setFlashEvent(data?.is_flash_event);
      setGradeID(data?.grades);
      if (data?.is_flash_event) {
        setStartDate(data.start_date);
        setEndDate(data.end_date);
        setFlashLink(data.event_link);
        setUploadedFlashFiles(data?.flash_img?.map((item) => [item]));
      }

      // Set form values after fetching user levels
      setFormValues(data, userLevels);

      fetchGradeData({
        session_year: selectedAcademicYear?.id,
        branch_id: selectedBranchIds.toString(),
        is_first: true,
        dataForEdit: data,
      });
      fetchSectionData({
        session_year: selectedAcademicYear?.id,
        branch_id: selectedBranchIds.toString(),
        grade_id: data?.grades?.join(','),
      });
      fetchMembersCount({
        role_id: data?.role_id.toString(),
        branch_id: selectedBranchIds.toString(),
        is_allowed_for_all: true,
        section_id: data?.section_mapping.join(','),
        grade_id: data?.grades.join(','),
      });
    } catch (error) {
      console.log(error);
      message.error('Failed to fetch announcement data');
    } finally {
      setLoading(false);
    }
  };

  const setFormValues = (data, userLevels) => {
    const selectedBranchIds = branchList
      .filter((branch) => data?.acad_session.includes(branch.id))
      .map((branch) => branch.branch.id);

    const userLevelNames = data?.role_id.map((id) => {
      const level = userLevels.find((level) => level.id === id.toString());
      return level ? level.level_name : id.toString();
    });

    formRef.current.setFieldsValue({
      branch: selectedBranchIds,
      user_level: userLevelNames,
      grade: [...new Set(data?.grades?.map((item) => item))],
      section: [...new Set(data?.section_mapping?.map((item) => item))],
    });

    setSelectedBranch(selectedBranchIds);
    setBranchId(selectedBranchIds);
    setGradeIds([...new Set(data?.grades?.map((each) => each))]);

    if (data?.section_mapping) {
      setSectionMappingIds([...new Set(data?.section_mapping?.map((item) => item))]);
    }
  };

  const setFormFieldValue = (grade_data, dataForEdit) => {
    let gradesIds = [...new Set(dataForEdit?.grades.map((each) => each))];
    setGradeIds(gradesIds);
    let sectionMappingIds = [
      ...new Set(dataForEdit?.section_mapping?.map((each) => each)),
    ];

    if (formRef.current) {
      formRef.current.setFieldsValue({
        grade: gradesIds,
        section: sectionMappingIds,
      });
    }
    setSectionMappingIds(sectionMappingIds);
  };

  const fetchUserLevel = () => {
    return axiosInstance
      .get(`${endpoints.userManagement.newUserLevelList}`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setUserLevelList(res?.data?.result);
          return res?.data?.result;
        }
        return [];
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  const fetchCategories = () => {
    axiosInstance
      .get(`${endpoints.createAnnouncement.announcementCategory}`, {})
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setCategories(res?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchMembersCount = (params = {}) => {
    axiosInstance
      .get(`${endpoints.createAnnouncement.membersCount}`, {
        params: {
          ...params,
          ...(feeReminderSelected ? { is_fee_reminder: true } : {}),
          ...(feeReminderSelected
            ? { session_year: selectedAcademicYear?.session_year }
            : { session_year: selectedAcademicYear?.id }),
        },
      })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setMembersCount(res?.data?.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchGradeData = (params = {}) => {
    const { dataForEdit, is_first, ...rest } = params;
    axiosInstance
      .get(`${endpoints.academics.grades}`, { params: { ...rest } })
      .then((res) => {
        if (res.data.status_code === 200) {
          const uniqueGrades = Array.from(
            res.data.data
              .reduce((map, obj) => map.set(obj.grade_id, obj), new Map())
              .values()
          );
          setGradeData(uniqueGrades);
          if (params.is_first) {
            setFormFieldValue(uniqueGrades, params.dataForEdit);
          }
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const fetchSectionData = (params = {}) => {
    if (sectionIDs.length === 1 && sectionIDs[0] === 'all') {
      return;
    }
    axiosInstance
      .get(endpoints.academics.sections, { params: { ...params } })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setSectionData(res?.data?.data);
          if (sectionMappingIds.length > 0) {
            const availableSectionIds = res?.data?.data.map((section) => section.id);
            const validSectionIds = sectionMappingIds.filter((id) =>
              availableSectionIds.includes(id)
            );
            formRef.current.setFieldsValue({
              section: validSectionIds,
            });
            setSectionMappingIds(validSectionIds);
          }
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const branchOptions = branchList?.map((each) => {
    return (
      <Option key={each?.branch?.id} value={each?.branch?.id}>
        {each?.branch?.branch_name}
      </Option>
    );
  });
  const gradeOptions = gradeData?.map((each) => {
    return (
      <Option
        key={each?.grade_id}
        value={each?.grade_id}
        grade_id={each?.grade_id}
        // disabled={
        //   branchId?.length > 1 && gradeIds?.length && !gradeIds?.includes(each?.grade_id)
        // }
      >
        {each?.grade_name}
      </Option>
    );
  });
  const sectionOptions = sectionData?.map((each) => {
    return (
      <Option key={each?.id} value={each?.id} sectionId={each?.section_id}>
        {each?.section__section_name}
      </Option>
    );
  });
  const categoryOptions = categories?.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.category_name}
      </Option>
    );
  });

  useEffect(() => {
    if (branchId && branchId.length > 0 && notiConfig?.enbl_brnches) {
      const hasMatchingBranch = branchId.some((id) =>
        notiConfig.enbl_brnches.includes(id)
      );

      if (hasMatchingBranch) {
        setAllowPublish(false);
      } else {
        setAllowPublish(true);
      }
    } else {
      setAllowPublish(false);
    }
  }, [branchId, notiConfig]);

  const handleBranch = (each) => {
    formRef.current.setFieldsValue({
      grade: [],
      section: [],
    });
    if (each?.length > 0) {
      let branchParam;
      let mainIds;
      if (each.some((item) => item.value === 'all')) {
        const allBranches = branchList.map((item) => item?.branch?.id).join(',');
        const allAcadSession = branchList.map((item) => item?.id);
        const branchToSet = branchList.map((item) => item?.branch?.id);
        branchParam = allBranches;
        mainIds = allAcadSession;
        setSelectedAcadSession(allAcadSession);
        formRef.current.setFieldsValue({
          branch: branchList.map((item) => item?.branch?.id),
        });
        setSelectedBranch(branchParam);
        setBranchId(branchToSet);
      } else {
        branchParam = each.map((item) => item.value).join(',');
        mainIds = each
          .map((item) => {
            const branchItem = branchList.find(
              (branch) => branch.branch.id === item.value
            );
            return branchItem ? branchItem.id : null;
          })
          .filter((id) => id !== null);
        setSelectedBranch(branchParam);
        setSelectedAcadSession(mainIds);
        setBranchId(branchParam.split(',').map(Number));
      }
      fetchGradeData({
        session_year: selectedAcademicYear?.id,
        branch_id: branchParam,
      });
    } else {
      setGradeData([]);
      setSectionData([]);
      setGradeID([]);
      setSectionIDs([]);
      setBranchId([]);
      setSelectedBranch([]);
      setSelectedAcadSession([]);
      setMembersCount(null);
      formRef.current.setFieldsValue({
        grade: [],
        section: [],
        user_level: [],
      });
      setSelectedUserLevels(undefined);
    }
  };

  const handleClearBranch = () => {
    setBranchId([]);
    setGradeIds([]);
    // setSectionIds([]);
    setSectionMappingIds([]);
    setGradeData([]);
    setSectionData([]);
  };

  const handleGrade = (each) => {
    const { grade: formGrades } = formRef.current.getFieldsValue();
    const selectedGrades = formGrades || [];

    let gradeParam;
    if (
      selectedGrades.includes('all') ||
      (each && each.some((item) => item.value === 'all'))
    ) {
      const allGrades = [...new Set(gradeData.map((item) => item.grade_id))];
      gradeParam = allGrades;
      setGradeID(allGrades);
      setGradeIds(allGrades);
      formRef.current.setFieldsValue({
        grade: allGrades,
        section: 'All',
      });
    } else {
      gradeParam = [
        ...new Set(
          selectedGrades.length > 0
            ? selectedGrades
            : each
            ? each.map((item) => item.value)
            : []
        ),
      ];
      setGradeID(gradeParam);
      setGradeIds(gradeParam);
    }

    if (gradeParam.length > 0) {
      setTimeout(() => {
        fetchSectionData({
          session_year: selectedAcademicYear?.id,
          branch_id: selectedBranch.toString(),
          grade_id: gradeParam.join(','),
        });
      }, 0);
    } else {
      setSectionData([]);
      setSectionIDs([]);
      setGradeID([]);
      setGradeIds([]);
      formRef.current.setFieldsValue({
        section: [],
      });
    }
  };

  const handleChangeSection = (selectedValues) => {
    if (
      selectedValues.includes('all') ||
      (gradeID.length === gradeData.length && branchId.length === branchList.length)
    ) {
      setSectionIDs(['all']);
      setSectionMappingIds(['all']);
      formRef.current.setFieldsValue({
        section: ['all'],
      });
    } else {
      if (selectedValues.length > 100) {
        message.warning('You can select up to 100 sections at a time.');
        selectedValues = selectedValues.slice(0, 100);
      }
      setSectionIDs(selectedValues);
      setSectionMappingIds(selectedValues);
      formRef.current.setFieldsValue({
        section: selectedValues,
      });
    }
  };

  const handleSelectGrade = (value, arr) => {
    if (value == 'all') {
      formRef.current.setFieldsValue({
        grade: arr,
      });
      setGradeIds(arr);
    } else {
      if (!gradeIds.includes(value)) {
        setGradeIds([...gradeIds, Number(value)]);
      }
    }
  };

  const handleDeSelectGrade = (each) => {
    formRef.current.setFieldsValue({
      section: [],
    });
    const index = gradeIds.indexOf(each?.value);
    const newGradeList = gradeIds.slice();
    newGradeList.splice(index, 1);
    setGradeIds(newGradeList);
    if (newGradeList.length == 0) {
      setMembersCount(0);
    }
  };

  const handleSelectSection = (each) => {
    if (each.value == 'all') {
      formRef.current.setFieldsValue({
        section: sectionData?.map((item) => item.id),
      });

      // setSectionIds(sectionData.map((item) => item.section_id));
      setSectionMappingIds(sectionData.map((item) => item.id));
    } else {
      if (!sectionMappingIds.includes(each.sectionId)) {
        // setSectionIds([...sectionMappingIds, Number(each.sectionId)]);
        setSectionMappingIds([...sectionMappingIds, Number(each.value)]);
      }
      if (!sectionMappingIds.includes(each.value)) {
        setSectionMappingIds([...sectionMappingIds, Number(each.value)]);
      }
    }
  };
  const handleDeSelectSection = (each) => {
    const sectionIdIndex = sectionMappingIds.indexOf(each?.sectionId);
    const newSectionIdList = sectionMappingIds.slice();
    newSectionIdList.splice(sectionIdIndex, 1);
    // setSectionIds(newSectionIdList);
    sectionMappingIds(newSectionIdList);
    const sectionMappingIdIndex = sectionMappingIds.indexOf(each?.value);
    const newSectionMappingIdList = sectionMappingIds.slice();
    newSectionMappingIdList.splice(sectionMappingIdIndex, 1);
    setSectionMappingIds(newSectionMappingIdList);
  };
  const handleClearSection = () => {
    // setSectionIds([]);
    setSectionMappingIds([]);
  };
  const handleUserLevel = (e) => {
    const selectedIds = e.map((levelName) => {
      const level = userLevelList.find((level) => level.level_name === levelName);
      return level ? level.id : levelName;
    });
    const unavailableUserLevels = originalUserLevels.filter(
      (id) => !userLevelList.some((level) => level.id === id.toString())
    );
    const combinedUserLevels = [...new Set([...selectedIds, ...unavailableUserLevels])];

    setSelectedUserLevels(combinedUserLevels.join(','));
    if (e.length === 0) {
      setMembersCount(null);
      formRef.current.setFieldsValue({
        grade: [],
        section: [],
      });
      setGradeID([]);
      setSectionIDs([]);
    } else if (selectedIds.includes('13')) {
      fetchGradeData({
        session_year: selectedAcademicYear?.id,
        branch_id: selectedBranch?.length
          ? selectedBranch.toString()
          : branchId?.join(','),
      });
    }
  };
  const handleClearUserLevel = () => {
    setSelectedUserLevels();
  };
  const handleShowModal = (isFlash) => {
    if (isFlash) {
      setFlashModal(true);
    } else {
      if (!branchId) {
        message.error('Please select branch');
        return;
      } else {
        setShowUploadModal(true);
      }
    }
  };

  const getLevelName = (id) => {
    const level = userLevelList.find((level) => level.id === id.toString());
    return level ? level.level_name : id;
  };

  const userLevelListOptions = userLevelList?.map((each) => {
    return (
      <Option key={each?.id} value={each.level_name}>
        {each?.level_name}
      </Option>
    );
  });

  const handlePublish = (asDraft) => {
    if (flashEvent && !startDate) {
      message.error('Please Select Start Date');
      return;
    }
    if (flashEvent && !endDate) {
      message.error('Please Select End Date');
      return;
    }
    if (flashEvent && !uploadedFlashFiles?.length > 0) {
      message.error('Please upload flash image');
      return;
    }
    if (flashEvent && !(new Date(endDate) >= new Date(startDate))) {
      message.error('End date should be gretaer than Start Date');
      return;
    }
    if (!selectedCategory) {
      message.error('Please select type');
      return;
    }
    if (!title) {
      message.error('Please add title');
      return;
    }
    if (!description) {
      message.error('Please add description');
      return;
    }
    if (!branchId) {
      message.error('Please select branch');
      return;
    }
    if (!selectedUserLevels) {
      message.error('Please select atleast one user level');
      return;
    }
    if (membersCount === null) {
      message.error('No members for announcement');
      return;
    }
    if (isStudentIncluded) {
      if (!gradeID) {
        message.error('Please select atleast one grade');
        return;
      }
    }

    if (Profanity(description)) {
      message.error('Description Contains Banned Words , Please Check');
      return;
    }
    if (Profanity(title)) {
      message.error('Title Contains Banned Words , Please Check');
      return;
    }

    let payLoad = {
      acad_session: selectedAcadSession,
      session_year: selectedAcademicYear?.id,
      role_id: selectedUserLevels.toString(),
      title: title,
      content: description,
      category: selectedCategory,
      is_edit: true,
    };
    if (asDraft) {
      payLoad['is_draft'] = true;
    }
    if (!props?.match?.params?.id) {
      if (sms == true) {
        payLoad['intimate_via_sms'] = true;
      }
      if (whatsapp == true) {
        payLoad['intimate_via_whatsapp'] = true;
      }
      if (email == true) {
        payLoad['intimate_via_email'] = true;
      }
    }
    if (isStudentIncluded) {
      payLoad['grades'] = gradeID;
      if (sectionMappingIds[0] !== 'all') {
        payLoad['section_mapping'] = sectionMappingIds;
      }
    } else {
      const allGrades = [...new Set(gradeData.map((item) => item.grade_id))];
      payLoad['grades'] = allGrades;
    }
    if (email == true) {
      payLoad['intimate_via_email'] = true;
    }
    if (flashEvent == true) {
      payLoad['is_flash_event'] = true;
      payLoad['start_date'] = moment(startDate).format('YYYY-MM-DD');
      payLoad['end_date'] = moment(endDate).format('YYYY-MM-DD');
      if (flashLink) {
        payLoad['event_link'] = flashLink;
      }

      if (uploadedFlashFiles?.length > 0) {
        payLoad['flash_img'] = uploadedFlashFiles.flat(1) || [];
      }
    }
    if (uploadedFiles?.length > 0) {
      payLoad['attachments'] = uploadedFiles.flat(1) || [];
    }

    if (!props?.match?.params?.id) {
      setLoading(true);
      axiosInstance
        .post(`${endpoints.createAnnouncementV2.publishAnnouncement}`, payLoad)
        .then((res) => {
          if (res.data.status_code === 200) {
            message.success(
              asDraft
                ? 'Announcement has saved in Draft'
                : 'Announcement Published Successfully'
            );
            setLoading(false);
            TrackerHandler('announcement_created', {
              status: asDraft ? 'draft' : 'publish',
            });
            history.push('/announcement-list');
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.response.data.message ?? 'Failed to create announcement');
        });
    } else {
      setLoading(true);
      axiosInstance
        .put(
          `${endpoints.createAnnouncementV2.retrieveUpdateDeleteAnnouncement}${props?.match?.params?.id}/`,
          payLoad
        )
        .then((res) => {
          if (res.data.status_code === 200) {
            message.success('Announcement Updated Successfully');
            setLoading(false);
            history.push('/announcement-list');
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.response.data.message ?? 'Failed to update announcement');
        });
    }
  };

  const handleRemoveUploadedFlashFile = (index) => {
    const newFileList = uploadedFlashFiles.slice();
    newFileList.splice(index, 1);
    setUploadedFlashFiles(newFileList);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (NavData && NavData.length) {
      NavData.forEach((item) => {
        if (
          item.parent_modules === 'Ebook' &&
          item.child_module &&
          item.child_module.length > 0
        ) {
          item.child_module.forEach((item) => {
            if (item.child_name === 'Ebook View') {
              setModuleId(item.child_id);
            }
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedUserLevels && selectedBranch.length > 0) {
      const params = {
        role_id: selectedUserLevels.toString(),
        branch_id: selectedBranch.toString(),
        is_allowed_for_all: true,
      };

      if (isStudentIncluded && gradeID.length > 0) {
        params.grade_id = gradeID.join(',');
        if (sectionIDs.length > 0 && sectionIDs[0] !== 'all') {
          params.section_id = sectionIDs.join(',');
        }
      }
      fetchMembersCount(params);
    } else {
      setMembersCount(null);
    }
  }, [selectedUserLevels, gradeID, sectionIDs, selectedBranch]);

  return (
    <Layout>
      <div className='row'>
        <div className='col-md-12'>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item href='/dashboard' className='th-grey'>
              Dashboard
            </Breadcrumb.Item>
            <Breadcrumb.Item href='/announcement-list' className='th-grey'>
              Announcements
            </Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1'>
              {props?.match?.params?.id ? 'Edit Announcement' : 'Create New Announcement'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/* {loading ? (
            <div
              className='row justify-content-center align-items-center'
              style={{ height: '20vh' }}
            >
              <Spin size='large' />
            </div>
          ) : ( */}

        <Spin spinning={loading} size='large'>
          <div className='col-md-12 mt-3'>
            <div className='row th-bg-white p-2'>
              <div className='row py-2'>
                <Form
                  id='filterForm'
                  className='text-left col-md-6 row w-100'
                  ref={formRef}
                >
                  <div className='col-md-6'>
                    <span className='th-grey th-14'>Type*</span>
                    <Select
                      value={selectedCategory}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className='th-grey th-bg-grey th-br-4 w-100 mt-1'
                      placement='bottomRight'
                      suffixIcon={<DownOutlined className='th-black-1' />}
                      dropdownMatchSelectWidth={false}
                      onChange={handleChange}
                      menuItemSelectedIcon={<CheckOutlined className='th-primary' />}
                    >
                      {categoryOptions}
                    </Select>
                  </div>
                  <div className='col-md-6 py-3 py-md-0'>
                    <span className='th-grey th-14'>Title*</span>
                    <Input
                      className='th-br-4 mt-1 th-16'
                      showCount
                      maxLength='100'
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                    />
                    <div className='text-right'>
                      <span className='th-red th-12 text-right'>Max. 100 Characters</span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <span className='th-grey th-14'>Branch*</span>
                    <Form.Item name='branch'>
                      <Select
                        allowClear
                        placeholder='Select Branch*'
                        showSearch
                        maxTagCount={1}
                        mode='multiple'
                        required={true}
                        suffixIcon={<DownOutlined className='th-grey' />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        optionFilterProp='children'
                        filterOption={(input, options) => {
                          return (
                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                        onChange={(e, value) => {
                          handleBranch(value);
                        }}
                        className='w-100 text-left th-black-1 th-select'
                      >
                        {branchList?.length > 1 && (
                          <>
                            <Option key={0} value={'all'}>
                              All
                            </Option>
                          </>
                        )}
                        {branchOptions}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='col-md-6 py-3 py-md-0'>
                    <span className='th-grey th-14'>Choose User Level*</span>
                    <Form.Item name='user_level'>
                      <Select
                        mode='multiple'
                        getPopupContainer={(trigger) => trigger.parentNode}
                        maxTagCount={2}
                        // allowClear={true}
                        suffixIcon={<DownOutlined className='th-grey' />}
                        className='th-grey th-bg-grey th-br-4 w-100 text-left mt-1'
                        placement='bottomRight'
                        showArrow={true}
                        disabled={
                          feeReminderSelected
                          // ||
                          // (props?.match?.params?.id &&
                          //   !([1, 8].includes(user_level) || is_superuser))
                        }
                        onChange={(e, value) => handleUserLevel(e)}
                        onClear={handleClearUserLevel}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, options) => {
                          return (
                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                      >
                        {userLevelListOptions}
                      </Select>
                    </Form.Item>
                  </div>
                  {isStudentIncluded && (
                    <div className='row mt-3 py-2'>
                      <div className='col-md-6'>
                        <span className='th-grey th-14'>Grades*</span>
                        <Form.Item name='grade'>
                          <Select
                            mode='multiple'
                            getPopupContainer={(trigger) => trigger.parentNode}
                            className='th-grey th-bg-grey th-br-4 w-100 text-left mt-1'
                            placement='bottomRight'
                            showArrow={true}
                            suffixIcon={<DownOutlined className='th-grey' />}
                            maxTagCount={1}
                            dropdownMatchSelectWidth={false}
                            onChange={(values) =>
                              handleGrade(values.map((value) => ({ value })))
                            }
                            onSelect={(value) =>
                              handleSelectGrade(
                                value,
                                gradeData.map((item) => item.grade_id)
                              )
                            }
                            onDeselect={handleDeSelectGrade}
                            filterOption={(input, options) => {
                              return (
                                options.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {gradeData.length > 1 && (
                              <Option key={0} value={'all'}>
                                All
                              </Option>
                            )}
                            {gradeOptions}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className='col-md-6'>
                        <span className='th-grey th-14'>Sections</span>
                        <Form.Item name='section'>
                          <Select
                            mode='multiple'
                            getPopupContainer={(trigger) => trigger.parentNode}
                            className='th-grey th-bg-grey th-br-4 w-100 text-left mt-1'
                            placement='bottomRight'
                            showArrow={true}
                            suffixIcon={<DownOutlined className='th-grey' />}
                            maxTagCount={1}
                            dropdownMatchSelectWidth={false}
                            onChange={handleChangeSection}
                            disabled={
                              gradeID.length === gradeData.length &&
                              branchId.length === branchList.length
                            }
                            filterOption={(input, options) => {
                              return (
                                options.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            value={sectionIDs}
                          >
                            {sectionData.length > 1 && (
                              <Option key={0} value='all'>
                                All
                              </Option>
                            )}
                            {sectionOptions}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  )}
                </Form>
                <div className='col-md-6'>
                  <span className='th-grey th-14'>Description*</span>
                  <div className='th-editor py-2'>
                    <TextArea
                      rows={5}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                {/* <div className='col-4'>
                    <span className='th-grey th-14'>Description</span>
                    <div className='th-editor py-2'>
                      <TextArea rows={4} onChange={(e) => setDescription(e.target.value)} />
                    </div>
                  </div> */}

                <div className='row mt-2'>
                  <div className='col-md-6 py-3 py-md-0'>
                    <span className='th-grey th-14'>Members</span>
                    <Input
                      className='th-br-4 mt-1 th-input th-16'
                      value={
                        membersCount !== null ? `${membersCount} Members Selected` : ''
                      }
                      // addonAfter={
                      //   membersCount !== null && (
                      //     <u
                      //       className='th-primary th-14 mr-3 th-pointer'
                      //       onClick={() => setShowMembersModal(true)}
                      //     >
                      //       Select Particular Members
                      //     </u>
                      //   )
                      // }
                    />
                  </div>
                  <div className='col-md-6 py-3 py-md-0'>
                    <span className='th-grey th-14'>Upload Attachments</span>
                    <div
                      className='row justify-content-start align-items-center th-br-4 py-1 mt-1'
                      style={{ border: '1px solid #D9D9D9' }}
                    >
                      <div className='col-md-10 col-9'>
                        <div className='row'>
                          {uploadedFiles?.map((item, index) => {
                            const fullName =
                              item[0]?.split('/')[item[0]?.split('/').length - 1];

                            const fileName =
                              fullName.split('.')[fullName?.split('.').length - 2];
                            const extension =
                              fullName.split('.')[fullName?.split('.').length - 1];

                            const fileName2 =
                              item[0]?.split('/')[item[0]?.split('/').length - 1];

                            return (
                              <div
                                title={fileName2}
                                className='th-br-15 col-md-3 col-5 px-1 px-md-3 py-2 mr-1 mb-1 th-bg-grey text-center d-flex align-items-center'
                              >
                                <span className='th-12 th-black-1 text-truncate'>
                                  {fileName2}
                                </span>
                                {/* <span className='th-12 th-black-1 '>.{extension}</span> */}

                                <span className='ml-md-3 ml-1 th-pointer '>
                                  <img
                                    src={smallCloseIcon}
                                    onClick={() => handleRemoveUploadedFile(index)}
                                  />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className='col-md-2 col-3 th-primary text-right th-pointer pl-0 pr-1 pr-md-2'
                        onClick={() => handleShowModal(false)}
                      >
                        <span className='th-12'>
                          {' '}
                          <u>Upload</u>
                        </span>
                        <span className='ml-3 pb-2'>
                          <img src={uploadIcon} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2 align-items-center'>
                  <div className='col-md-2 py-3 py-md-0'>
                    <Checkbox
                      onChange={(e) => setFlashEvent(e.target.checked)}
                      checked={flashEvent}
                    >
                      <span className='th-grey th-14'> Is Flash Event?</span>
                    </Checkbox>
                  </div>
                  {flashEvent ? (
                    <>
                      <div className='col-md-2 py-3 py-md-0'>
                        <span className='th-grey th-14'>Start Date*</span>
                        <div>
                          <DatePicker
                            disabledDate={(current) => {
                              return current < moment().startOf('day');
                            }}
                            className='text-left'
                            onChange={(e) => setStartDate(e.format('YYYY-MM-DD'))}
                            value={moment(startDate)}
                          />
                        </div>
                      </div>

                      <div className='col-md-2 py-3 py-md-0'>
                        <span className='th-grey th-14'>End Date*</span>
                        <div>
                          <DatePicker
                            disabledDate={(current) => {
                              return current < moment().startOf('day');
                            }}
                            onChange={(e) => setEndDate(e.format('YYYY-MM-DD'))}
                            value={moment(endDate)}
                          />
                        </div>
                      </div>
                      <div className='col-md-3 py-3 py-md-0'>
                        <span className='th-grey th-14'>Upload Flash Image*</span>
                        <div
                          className='row justify-content-start align-items-center th-br-4 py-1 mt-1'
                          style={{ border: '1px solid #D9D9D9' }}
                        >
                          <div className='col-10 col-md-9'>
                            <div className='row no-gutters'>
                              {uploadedFlashFiles?.map((item, index) => {
                                const fileName2 =
                                  item[0]?.split('/')[item[0]?.split('/').length - 1];

                                return (
                                  <div
                                    key={index}
                                    title={fileName2}
                                    className='col-12 px-2 py-1 th-bg-grey d-flex align-items-center justify-content-between'
                                  >
                                    <span
                                      className='th-12 th-black-1 text-truncate'
                                      style={{ maxWidth: '80%' }}
                                    >
                                      {fileName2}
                                    </span>
                                    <span className='ml-2 th-pointer'>
                                      <img
                                        src={smallCloseIcon}
                                        onClick={() =>
                                          handleRemoveUploadedFlashFile(index)
                                        }
                                        alt='Remove'
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div
                            className='col-2 col-md-3 th-primary text-right th-pointer pl-0 pr-1 pr-md-2'
                            onClick={() => handleShowModal(true)}
                          >
                            <span className='th-12'>
                              <u>Upload</u>
                            </span>
                            <span className='ml-2'>
                              <img src={uploadIcon} alt='Upload' />
                            </span>
                          </div>
                        </div>
                        <span className='th-grey th-12'>Dimensions: 600*300</span>
                      </div>
                      <div className='col-md-3'>
                        <span className='th-grey th-14'>Link</span>
                        <div className='th-editor py-2'>
                          <Input
                            style={{ fontSize: '10px' }}
                            onChange={(e) => setFlashLink(e.target.value)}
                            value={flashLink}
                            placeholder='dimentions'
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className='row mt-4 py-2'>
                  <div className='col-md-8 d-flex align-items-center'>
                    {!props?.match?.params?.id ? (
                      <>
                        {/* <Checkbox.Group
                        options={intimationOptions}
                        onChange={(e) => setIntimation(e)}
                      /> */}
                        {/* {notiConfig?.is_email == true ? (
                            <Checkbox onChange={(e) => setEmail(e.target.checked)}>
                              Intimate Via Email
                            </Checkbox>
                          ) : (
                            ''
                          )} */}
                        {notiConfig?.is_sms == true ? (
                          <Checkbox onChange={(e) => setSMS(e.target.checked)}>
                            Intimate Via SMS
                          </Checkbox>
                        ) : (
                          ''
                        )}
                        {notiConfig?.is_whatsapp == true ? (
                          <Checkbox onChange={(e) => setWhatsapp(e.target.checked)}>
                            Intimate Via Whatsapp
                          </Checkbox>
                        ) : (
                          ''
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className='col-md-4 d-flex justify-content-md-end py-4 py-md-0'>
                    {!props?.match?.params?.id ? (
                      <>
                        <Button
                          className='th-bg-grey th-black-2 th-br-4 th-fw-500 th-14 th-pointer col-md-6 col-5 mr-5 mr-md-2'
                          style={{ border: '1px solid #D9D9D9' }}
                          onClick={() => handlePublish(true)}
                        >
                          Save as Draft
                        </Button>
                        {allowPublish && (
                          <Button
                            className='th-bg-primary th-white th-br-4 th-fw-500 th-14 th-pointer col-md-6 col-5'
                            onClick={() => handlePublish(false)}
                            // disabled={!allowPublish}
                          >
                            Publish
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        className='th-bg-grey th-black-2 th-br-4 th-fw-500 th-14 th-pointer col-md-6 col-5 mr-5 mr-md-2'
                        style={{ border: '1px solid #D9D9D9' }}
                        onClick={() => handlePublish()}
                      >
                        Update
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
        {/* )} */}
        <UploadModal
          show={showUploadModal}
          branchId={branchId}
          handleClose={handleUploadModalClose}
          setUploadedFiles={handleUploadedFiles}
          setFlashUploadedFiles={handleFlashUploadedFiles}
          flashModal={flashModal}
          handleFlashClose={handleUploadFlashModalClose}
        />
        <MembersModal show={showMembersModal} handleClose={handleMembersModalClose} />
      </div>
    </Layout>
  );
};

export default CreateAnnouncement;
