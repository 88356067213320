import React from 'react';
import CreateUser from '../CreateUser';
import V1CreateUser from '../../../../../containers/user-management/create-user';
import V1EditUser from '../../../../../containers/user-management/edit-user';
import { useSelector } from 'react-redux';
import Layout from 'containers/Layout';
import CreateUserTab from '../CreateUserTab';

const CreateUserConfig = ({ match, history }) => {
  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch?.branch
  );
    
  const systemConfig = useSelector(
    (state) => state.commonFilterReducer?.systemConfig
  );
  const config = systemConfig?.['create-user']

  return (
    <React.Fragment>
      { config?.includes(selectedBranch?.id) ? (
        window.location.pathname.includes('edit') ? (
          <Layout>
            <CreateUser />
          </Layout>
        ) : (
          <CreateUserTab />
        )
      ) : (
        <div className='user-management-container'>
          {window.location.pathname.includes('edit') ? (
            <V1EditUser match={match} history={history} />
          ) : (
            <V1CreateUser match={match} history={history} />
          )}
        </div>
      )}
    </React.Fragment>
  );
};
// config?.includes(selectedBranch?.id?.toString()) &&
export default CreateUserConfig;
