/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState, useContext, useRef } from 'react';
import Layout from '../../../Layout';
import {
  Grid,
  Divider,
  TextField,
  Button,
  useTheme,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { generateQueryParamSting } from '../../../../utility-functions';
import CommonBreadcrumbs from '../../../../components/common-breadcrumbs/breadcrumbs';
import '../../../../containers/master-management/master-management.css';
import Loading from '../../../../components/loader/loader';
import TabPanel from '../../../../components/tab-panel';
import axiosInstance from 'config/axios';
import endpoints from 'config/endpoints';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import './student-report-card.css';
import AssesmentReportNew from 'containers/assessment-central/assesment-report-card/newReportPrint';
import ReportCardNewBack from 'containers/assessment-central/assesment-report-card/reportCardNewBack';
import GrievanceModal from 'v2/FaceLift/myComponents/GrievanceModal';
import EypReportCardPdf from 'containers/assessment-central/assesment-report-card/eypReportCard/eypPdf';
import FeeReminderAssesment from 'containers/assessment-central/Feereminder';
import { IsOrchidsChecker } from 'v2/isOrchidsChecker';
import PhysicalEducationReportCard from '../../assesment-report-card/physicalEducationReportCard/physicalEducationReportCard.js';
import axios from 'axios';
import { X_DTS_HOST } from 'v2/reportApiCustomHost';
import ReactToPrint from 'react-to-print';
import endpointsV2 from 'v2/config/endpoints';
import VlcReportCardPdf from '../../assessment-report-types/viewReportCard/vlcReportPrint';
import SportsReportCard from '../../assessment-report-types/viewReportCard/sportReportPrint/SportsReportCard';
import { Tabs, Breadcrumb, message, Spin, Empty } from 'antd';
// const isOrchids =
//   window.location.host.split('.')[0] === 'orchids' ||
//   window.location.host.split('.')[0] === 'qa'
//     ? true
//     : false;
const isOrchids = IsOrchidsChecker();

const StudentReportCard = () => {
  const themeContext = useTheme();
  const componentRef = useRef();
  const { token } = JSON.parse(localStorage.getItem('userDetails')) || {};
  const { setAlert } = useContext(AlertNotificationContext);
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [reportCardDataNew, setReportCardDataNew] = useState([]);
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );
  const {
    erp = '',
    role_details,
    first_name,
  } = JSON.parse(localStorage.getItem('userDetails')) || {};
  const { id: sessionYearId = '' } =
    JSON.parse(sessionStorage.getItem('acad_session')) || {};
  const [showGrievanceModal, setShowGrievanceModal] = useState(false);
  const { user_level } = JSON.parse(localStorage.getItem('userDetails')) || {};

  const systemConfig = useSelector((state) => state.commonFilterReducer?.systemConfig);
  const eypConfig = systemConfig?.eyp_rc_grades;
  const showPEConfig = systemConfig?.['rc-pe-enle-grades'];
  const [peReportCardData, setPEReportCardData] = useState([]);
  const [principalSignData, setPrincipalSignData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sportsPdfBlob, setSportsPdfBlob] = useState(null);
  const [vlcPdfBlob, setVlcPdfBlob] = useState(null);
  const [sportsPdfUrl, setSportsPdfUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const fetchPrincipalSignature = (params = {}) => {
    axiosInstance
      .get(`${endpointsV2.principalSignAcad}`, {
        params: params,
      })
      .then((res) => {
        setPrincipalSignData(res.data?.result.results);
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (tabValue == 2) {
      fetchVlcReportData();
    }
    if (tabValue == 3) {
      fetchSportsReportData();
    }
  }, [tabValue]);

  const fetchVlcReportData = () => {
    setIsLoading(true);
    axiosInstance
      .get(`${endpointsV2.assessmentReportConfig.getVlcReport}`, {
        params: {
          erp_id: role_details?.erp_user_id,
          section_mapping: gradeList[0]?.sec_map_id,
          term_id: 1,
        },
      })
      .then((res) => {
        if (res.data && res.data.result) {
          VlcReportCardPdf(res.data.result, principalSignData)
            .then(({ blob, url }) => {
              setPdfUrl(url);
              setVlcPdfBlob(blob);
            })
            .catch((error) => {
              message.error('Failed to generate report card');
              console.error('Error generating PDF:', error);
            });
        }
      })
      .catch((error) => {
        console.error('Error fetching VLC report:', error);
        message.error(error?.response?.data?.message ?? 'Error fetching VLC report');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchSportsReportData = () => {
    setIsLoading(true);
    axiosInstance
      .get(`${endpointsV2.assessmentReportConfig.getSportsReport}`, {
        params: {
          erp_id: role_details?.erp_user_id,
          section_mapping: gradeList[0]?.sec_map_id,
          term_id: 1,
        },
      })
      .then((res) => {
        const { blob, url } = SportsReportCard(res.data.result, principalSignData);
        setSportsPdfUrl(url);
        setSportsPdfBlob(blob);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message ?? 'Error fetching Sports report');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDownload = (title, pdfBlob) => {
    if (pdfBlob) {
      const fileName = `${title}-${first_name}-${erp}.pdf`;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };
  const renderReportCardNew = (componentRef) => {
    switch (tabValue) {
      case 0:
        return (
          <AssesmentReportNew
            principalSignData={principalSignData}
            reportCardDataNew={reportCardDataNew}
            ref={componentRef}
          />
        );
      case 1:
        return (
          <ReportCardNewBack
            principalSignData={principalSignData}
            reportCardDataNew={reportCardDataNew}
            ref={componentRef}
          />
        );

      case 2:
        return renderPdfPreview(pdfUrl, 'Arts & SOCH', vlcPdfBlob);
      case 3:
        return renderPdfPreview(sportsPdfUrl, 'Sports', sportsPdfBlob);
    }
  };

  const renderPdfPreview = (url, title, pdfBlob) => {
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px',
          }}
        >
          <Spin size='large' />
        </div>
      );
    }
    if (!url) {
      return (
        <Empty className='mb-4' description={`No ${title} report card to preview`} />
      );
    }
    return (
      <div>
        <div className='text-right'>
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={() => handleDownload(title, pdfBlob)}
            style={{ marginBottom: '10px' }}
          >
            Download {title} Report
          </Button>
        </div>
        <iframe
          src={url}
          style={{ width: '100%', height: '500px', border: 'none' }}
          title={`${title} Report PDF`}
        />
      </div>
    );
  };
  const handleCloseGrievanceModal = () => {
    setShowGrievanceModal(false);
  };
  const getGrades = () => {
    axiosInstance
      .get(
        `${endpoints.reportCard.studentReportGrade}?session_year=${selectedAcademicYear?.id}`
      )
      .then((response) => setGradeList(response?.data?.data))
      .catch(() => {});
  };

  const handleGrade = (event, value) => {
    setSelectedGrade('');
    const { published = '' } = value || {};
    // if (!published) {
    //   setAlert('error', 'Report card not published');
    //   return;
    // }
    setSelectedGrade(value);
    fetchPrincipalSignature({
      branch_id: selectedBranch?.branch?.id,
      session_year: selectedAcademicYear?.id,
      user_level: 8,
    });
    let paramObj = {
      acad_session_id: selectedBranch?.id,
      erp_id: erp,
      grade_id: value?.gr_id,
    };
    let params = `?${generateQueryParamSting({ ...paramObj })}`;
    if (value?.gr_id) {
      eypConfig.includes(String(value?.gr_id))
        ? fetchEypReportCard(value?.gr_id)
        : fetchReportCardData(params);
    }
  };

  const fetchPEReportCardData = (params = {}) => {
    setLoading(true);
    axios
      .get(`${endpoints.assessmentReportTypes.physicalEducationReportCard}`, {
        params: params,
        headers: { 'X-DTS-HOST': X_DTS_HOST, authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setPEReportCardData(response.data);
        } else {
          setPEReportCardData([]);
        }
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchEypReportCard = (grade_id) => {
    let obj = {};
    obj.acad_session_id = selectedBranch?.id;
    obj.grade_id = grade_id;
    obj.erp_id = erp;
    setLoading(true);
    axiosInstance
      .get(`${endpoints.assessmentReportTypes.eypReportCard}`, { params: { ...obj } })
      .then((response) => {
        if (response?.data) {
          // generateEypReport(response?.data?.result);
          EypReportCardPdf(response?.data?.result, selectedBranch?.branch?.branch_name);
          setLoading(false);
        }
      })
      .catch((err) => {
        setAlert('error', err?.response?.data?.message);
        setLoading(false);
      });
  };
  const fetchReportCardData = (params) => {
    axiosInstance
      .get(`${endpoints.assessmentReportTypes.reportCardDataNew}${params}`)
      .then((result) => {
        setLoading(true);
        setReportCardDataNew(result?.data?.result);
        setLoading(false);
      })
      .catch((error) => {
        setAlert('error', error?.response?.data?.message);
        setSelectedGrade('');
      });
    setLoading(false);
  };

  useEffect(() => {
    getGrades();
  }, []);

  // useEffect(() => {
  //   if (showPEConfig?.includes(selectedGrade?.gr_id))
  //     fetchPEReportCardData({
  //       branch_id: selectedBranch?.branch?.id,
  //       grade_id: selectedGrade?.gr_id,
  //     });
  // }, [selectedGrade]);

  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <CommonBreadcrumbs
          componentName='Assessment'
          childComponentName='Report-Card'
          isAcademicYearVisible={true}
        />
        {user_level == 13 ? <FeeReminderAssesment /> : ''}
        <div
          className='student-report-card'
          style={{
            // background: 'white',
            height: '90vh',
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
        >
          <Grid
            container
            spacing={isMobile ? 3 : 5}
            style={{
              width: '99%',
              margin: '0 auto',
            }}
          >
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={handleGrade}
                id='grade'
                value={selectedGrade || {}}
                options={gradeList || []}
                getOptionLabel={(option) => option?.gr_name || ''}
                getOptionSelected={(option, value) => option?.gr_id === value?.gr_id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Grade'
                    placeholder='Grade'
                  />
                )}
              />
            </Grid>
            <Grid xs={0} sm={6} />
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {selectedGrade && reportCardDataNew?.report?.length > 0 && (
              <Grid item xs={12}>
                <>
                  <div className='row justify-content-between'>
                    <TabPanel
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      tabValues={['Front', 'Back', 'Arts & SOCH', 'Sports']}
                    />
                    {![2, 3]?.includes(tabValue) ? (
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            style={{ fontSize: 15 }}
                          >
                            Download Report Card
                          </Button>
                        )}
                        content={() => componentRef.current} // Use the ref content here
                        documentTitle={`Eduvate ${
                          tabValue === 0
                            ? 'Front'
                            : tabValue === 1
                            ? 'Back'
                            : 'PhysicalEducationReportCard'
                        } - ${reportCardDataNew?.user_info?.name}`}
                      />
                    ) : tabValue === 2 ? (
                      peReportCardData?.data?.map((item) => item?.criteria_title).flat()
                        ?.length > 0 ? (
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              variant='contained'
                              size='small'
                              color='primary'
                              style={{ fontSize: 15 }}
                            >
                              Download Report Card
                            </Button>
                          )}
                          content={() => componentRef.current} // Use the ref content here
                          documentTitle={`Eduvate ${
                            tabValue === 0
                              ? 'Front'
                              : tabValue === 1
                              ? 'Back'
                              : 'PhysicalEducationReportCard'
                          } - ${reportCardDataNew?.user_info?.name}`}
                        />
                      ) : null
                    ) : null}
                  </div>
                  <Box style={{ margin: '20px auto' }}>
                    {renderReportCardNew(componentRef)}
                  </Box>
                </>
              </Grid>
            )}
          </Grid>
          {(user_level == 13 || user_level == 12) && isOrchids ? (
            <div
              className='row justify-content-end'
              style={{ position: 'fixed', bottom: '5%', right: '2%' }}
            >
              <div
                className='th-bg-white px-2 py-1 th-br-6 th-pointer'
                style={{ border: '1px solid #d9d9d9' }}
                onClick={() => setShowGrievanceModal(true)}
              >
                Issues with Report Card/Marks?
                <br />
                <span className='th-primary pl-1' style={{ textDecoration: 'underline' }}>
                  Raise your query
                </span>
              </div>
            </div>
          ) : null}
          {showGrievanceModal && (
            <GrievanceModal
              module={'Report Card'}
              title={'Report Card Related Query'}
              showGrievanceModal={showGrievanceModal}
              handleClose={handleCloseGrievanceModal}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default StudentReportCard;
