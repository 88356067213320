import React from 'react';
import Layout from 'containers/Layout';
import TeacherDashboardConfigOff from './TeacherDashboardConfigOff';
import TeacherDashboardConfigOn from './TeacherDashboardConfigOn';

import { useSelector } from 'react-redux';

const TeacherDashoboardNew = () => {
  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );
  const systemConfig = useSelector(
    (state) => state.commonFilterReducer?.systemConfig
  );
 
  const configOn =systemConfig?.['teacher_dashboard_cfg'].includes(selectedBranch?.branch?.id)
  const newTimeTable =systemConfig?.['tt-enabled-v2'].includes(selectedBranch?.branch?.id)

  return (
    <Layout>
      <div className=''>
        {configOn ? (
          <TeacherDashboardConfigOn newTimeTable={newTimeTable} />
        ) : (
          <TeacherDashboardConfigOff />
        )}
      </div>
    </Layout>
  );
};

export default TeacherDashoboardNew;
