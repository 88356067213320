import React, { useEffect, useState } from 'react';
import TimeTableNew from '../time-table/index';
import TeacherTimeTable from './teacherTimeTable';
import { useSelector } from 'react-redux';
import StudentTimeTable from 'v2/FaceLift/Timetable/StudentTimeTable';
import TimeTable from 'v2/FaceLift/Timetable/TimeTable';

const TimeTableCheck = () => {
  const [periodConfig, setPeriodConfig] = useState(null);
  const user_level = JSON.parse(localStorage.getItem('userDetails'))?.user_level || {};
  const isTeacher = user_level == 11;
  const isStudent = user_level === 13;
  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );
  const systemConfig = useSelector((state) => state.commonFilterReducer?.systemConfig);
  const showNewTimeTable = systemConfig?.['tt-enabled-v2'].includes(selectedBranch?.branch?.id);


  useEffect(() => {
    const erp_config = JSON.parse(localStorage.getItem('userDetails'))?.erp_config;
    setPeriodConfig(erp_config);
  }, [periodConfig]);

 
  return (
    <>
        {showNewTimeTable ? (
          isStudent ? (
            <StudentTimeTable />
          ) : (
            <TimeTable />
          )
        ) : isTeacher ? (
          <TeacherTimeTable />
        ) : (
          <TimeTableNew />
        )}
    </>
  );
};

export default TimeTableCheck;
