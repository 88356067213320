import React, { useState, useEffect } from 'react';
import AnnouncementCard from 'v2/FaceLift/myComponents/AnnouncementCards';
import axios from 'v2/config/axios';
import endpoints from 'v2/config/endpoints';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSortedAnnouncements } from 'v2/generalAnnouncementFunctions';
import NoDataIcon from 'v2/Assets/dashboardIcons/teacherDashboardIcons/noAnnouncementIcon.svg';
import DetailsModal from 'v2/FaceLift/Announcement/announcementList/DetailsModal';

const Announcements = (props) => {
  const history = useHistory();
  const [announcementData, setAnnouncementData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState([]);
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );

  useEffect(() => {
    if (selectedAcademicYear)
      fetchAnnouncementData({
        acad_session: selectedBranch?.id,
      });
  }, [selectedAcademicYear]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchAnnouncementData = (params = {}) => {
    axios
      .get(`${endpoints.adminDashboard.announcementsList}`, {
        params: { ...params },
      })
      .then((response) => {
        if (response.data.results?.length > 0) {
          setAnnouncementData(response?.data?.results);
        }
      })
      .catch((error) => console.log(error));
  };

  const announcementListData = getSortedAnnouncements(announcementData);

  return (
    <div
      className={
        props?.isSchoolWall
          ? `py-3 th-br-20 th-bg-white th-post-announcements`
          : 'th-bg-white th-br-5 py-3 mt-3 px-2 shadow-sm mb-2'
      }
    >
      <div className='col-md-12 mt-2 pb-2 th-black-1 th-16 th-fw-500 '>
        <span className=''>Announcements </span>
      </div>
      <div
        className='col-md-12 my-2 th-custom-scrollbar'
        style={{
          height: props?.scrollHeight,
          overflowY: 'auto',
        }}
      >
        {announcementListData?.length > 0 ? (
          announcementListData?.map((item) => {
            return (
              <div className='th-14 th-fw-500 th-black-1 th-lh-20' key={item?.id}>
                {item?.date}
                {item?.events.map((item) => (
                  <div
                    // onClick={() => {
                    //   setShowModal(true);
                    //   setCurrentAnnouncement(item);
                    // }}
                  >
                    <AnnouncementCard data={item} key={item?.id} />
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <div className='d-flex justify-content-center mt-5'>
            <img src={NoDataIcon} />
          </div>
        )}
      </div>
      <div className='col-md-12 pt-1 text-right'>
        <div className='th-primary'>
          {announcementListData?.length > 0 ? (
            <div
              onClick={() => history.push('./announcement-list')}
              className='th-black-1 th-bg-grey p-2 th-br-8 badge th-pointer'
              style={{ outline: '1px solid #d9d9d9' }}
            >
              View All
            </div>
          ) : (
            <span> &nbsp;</span>
          )}
        </div>
      </div>
      {showModal && (
        <DetailsModal
          data={currentAnnouncement}
          show={showModal}
          handleClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Announcements;
