import Layout from 'containers/Layout';
import React from 'react';
import SchoolWallConfigOn from './SchollWallConfigOn';
import BlogWall from 'containers/newBlog/BlogWall';
import { useSelector } from 'react-redux';

const SchoolWall = () => {
  const systemConfig = useSelector((state) => state.commonFilterReducer?.systemConfig);
  const showNewSchollWall = systemConfig?.['social_media_feed_enabled'];

  return <Layout>{showNewSchollWall ? <SchoolWallConfigOn /> : <BlogWall />}</Layout>;
};

export default SchoolWall;
