import React from 'react';
import StudentHomework from 'containers/homework/student-homework/student-homework';
import StudentHomeworkNew from 'containers/homework/student-homework/studentSide';
import { useSelector } from 'react-redux';

const StudentHwConfig = () => {
  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );
  const systemConfig = useSelector((state) => state.commonFilterReducer?.systemConfig);
  const configOn = systemConfig?.hw_enhancement.includes(selectedBranch?.branch?.id);

  return <>{configOn ? <StudentHomeworkNew /> : <StudentHomework />}</>;
};

export default StudentHwConfig;
