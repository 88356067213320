import React from 'react';
import NewDiary from 'v2/FaceLift/Diary/DiaryNew/index';
import { useSelector } from 'react-redux';

const DiaryMain = () => {

  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );
  const systemConfig = useSelector((state) => state.commonFilterReducer?.systemConfig);
  const newTimetableFLow = systemConfig?.['tt-enabled-v2'].includes(
    selectedBranch?.branch?.id
  );

  return (
    <>
      {window.location.pathname.includes('/diary/student') ? (
        <NewDiary />
      ) : (
        <NewDiary newTimetableFLow={newTimetableFLow} />
      )}
    </>
  );
};

export default DiaryMain;
