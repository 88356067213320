import React from 'react';
import CoordinatorHomework from 'containers/homework/coordinator-homework';
import CoordinatorTeacherHomeworkv2 from 'containers/homework/coordinator-homework/newMgmtView';
import { useSelector } from 'react-redux';

const CheckCoordinatorHW = () => {
  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );
  const systemConfig = useSelector((state) => state.commonFilterReducer?.systemConfig);
  const isHWAutoAssign = systemConfig?.hw_auto_asgn.includes(selectedBranch?.branch?.id);
  const configOn = systemConfig?.hw_enhancement.includes(selectedBranch?.branch?.id);
  
  return (
    <>
      {configOn ? (
        <CoordinatorTeacherHomeworkv2 isHWAutoAssign={isHWAutoAssign} />
      ) : (
        <CoordinatorHomework />
      )}
    </>
  );
};

export default CheckCoordinatorHW;
