import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Input,
  Table,
  message,
  Pagination,
  Empty,
  Row,
  Col,
  Spin,
  Form,
  Modal,
  Popconfirm,
  Tooltip,
  Select,
  Drawer,
} from 'antd';
import {
  PlusCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  SyncOutlined,
  DownOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import endpoints from 'config/endpoints';
import axiosInstance from 'config/axios';
import axios from 'axios';
import { useForm } from 'antd/lib/form/Form';
const LevelMapping = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [formRef] = useForm();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  const [search, setSearch] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [editId, setEditId] = useState();
  const [userLevelList, setUserLevelList] = useState([]);
  const [rowId, setRowId] = useState();
  const [drawerTableData, setDrawerTableData] = useState([]);
  const [drawerOPen, setDrawerOpen] = useState(false);
  const [drawerSearch, setDrawerSearch] = useState('');
  const [page, setPage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [toRemove, setToRemove] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const extractContent = (s) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  useEffect(() => {
    fetchTableData();
  }, [currentPage]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFetchTableData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);
  const handleFetchTableData = () => {
    if (currentPage == 1) {
      fetchTableData();
    } else {
      setCurrentPage(1);
    }
  };
  const fetchTableData = () => {
    setTableLoading(true);
    let params = {
      page: currentPage,
      page_size: pageSize,
    };
    if (search?.length > 0) {
      params = {
        ...params,
        search: search,
      };
    }
    axiosInstance
      .get(`${endpoints.permissionClass.levelMappingApi}`, {
        params: params,
      })
      .then((response) => {
        if (response?.status == 200) {
          setTableData(response?.data);
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setTableLoading(false);
      });
  };
  const handleCreate = () => {
    const level_id = formRef?.getFieldsValue()?.level_id;
    const level_description = formRef?.getFieldsValue()?.level_description;
    setModalLoading(true);
    const params = {
      level_id: level_id,
      description: level_description,
    };
    axiosInstance
      .post(`${endpoints.permissionClass.levelMappingApi}`, params)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          message.success('Hurray! Level mapping created successfully');
          handleCloseModal();
          if (search?.length > 0) {
            setSearch('');
          } else {
            handleFetchTableData();
          }
        } else {
          message.error('OOPS! Seems this combinations already exists');
          return;
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setModalLoading(false);
      });
  };
  const handleEdit = () => {
    const level_id = formRef?.getFieldsValue()?.level_id;
    const level_description = formRef?.getFieldsValue()?.level_description;
    setModalLoading(true);
    const params = {
      level_id: level_id,
      description: level_description,
    };
    axiosInstance
      .put(`${endpoints.permissionClass.levelMappingApi}${editId}/`, params)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          message.success('Hurray! Level mapping updated successfully');
          handleCloseModal();
          fetchTableData();
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setModalLoading(false);
      });
  };
  const handleDelete = ({ delId }) => {
    setLoading(true);
    axiosInstance
      .delete(`${endpoints.permissionClass.levelMappingApi}${delId}/`)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          message.success('Hurray! Level mapping deleted successfully');
          fetchTableData();
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleOpenModal = ({ actionKey, editId, rowData }) => {
    setOpenModal(true);
    fetchUserLevels();
    if (actionKey === 'edit') {
      formRef.setFieldsValue({
        level_id: rowData?.level,
        level_description: rowData?.description,
      });
      setEditId(editId);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    formRef.resetFields();
    setEditId();
  };
  const fetchUserLevels = () => {
    let api = endpoints.userManagement.newUserLevelList;
    axiosInstance
      .get(api)
      .then((result) => {
        setUserLevelList(result?.data?.result);
      })
      .catch((error) => {
        message.error(error.response?.data?.message ?? 'Something Went Wrong !');
      });
  };
  const userLevelOptions = userLevelList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.level_name}
      </Option>
    );
  });

  useEffect(() => {
    if (drawerOPen) {
      fetchDrawerTableData();
    }
  }, [page]);
  useEffect(() => {
    if (drawerOPen) {
      const timeout = setTimeout(() => {
        handleFetchDrawerTableData();
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [drawerSearch]);
  const handleFetchDrawerTableData = () => {
    if (page == 1) {
      fetchDrawerTableData();
    } else {
      setPage(1);
    }
  };
  const fetchDrawerTableData = () => {
    setModalLoading(true);
    let params = {
      page: page,
      page_size: pageSize,
    };
    if (drawerSearch?.length > 0) {
      params = {
        ...params,
        search: drawerSearch,
      };
    }
    axiosInstance
      .get(`${endpoints.permissionClass.assignPermissionApi}?instance_id=${rowId}`, {
        params: params,
      })
      .then((response) => {
        console.log(response, 'response');
        if (response?.status == 200) {
          console.log(response?.data?.results, 'response1');
          let data = response?.data?.results;
          const ids = data.filter((each) => each?.is_exists).map((each) => each?.id);
          setToRemove(ids);
          setSelectedRowKeys(ids);
          setSelectedRows(ids);
          setDrawerTableData(response?.data);
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setModalLoading(false);
      });
  };
  const handleEditPermission = () => {
    setModalLoading(true);
    const params = {
      // permission_class_id: rowId,
      to_add: selectedRows,
      to_remove: toRemove,
    };
    axiosInstance
      .put(`${endpoints.permissionClass.levelMappingApi}${rowId}/`, params)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          message.success('Hurray! Level mapping updated successfully');
          // handleClosePermissionDrawer();
          setEditMode(false);
          fetchDrawerTableData();
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setModalLoading(false);
      });
  };
  useEffect(() => {
    if (rowId) {
      handleFetchDrawerTableData();
    }
  }, [rowId]);
  const handleOpenPermissionDrawer = ({ rowId }) => {
    setDrawerOpen(true);
    setRowId(rowId);
    // handleFetchDrawerTableData();
  };
  const handleClosePermissionDrawer = () => {
    setDrawerOpen(false);
    setDrawerSearch('');
    setRowId();
  };
  const columns = [
    {
      title: <span className='th-white th-16 th-fw-700'>Sl No.</span>,
      align: 'center',
      render: (data, row, index) => (
        <span className='th-black-1 th-16'>
          {(currentPage - 1) * pageSize + index + 1}.
        </span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>User Level</span>,
      align: 'center',
      render: (data, row, index) => (
        <span className='th-black-1 th-16'>{row?.level}</span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Level Description</span>,
      align: 'center',
      render: (data, row) => (
        <span className='th-black-1 th-16'>
          {' '}
          {extractContent(row?.description).length > 30 ? (
            <Tooltip
              autoAdjustOverflow='false'
              placement='bottomLeft'
              title={extractContent(row?.description)}
              overlayStyle={{ maxWidth: '30%', minWidth: '20%' }}
            >
              {extractContent(row?.description).substring(0, 30) + '...'}
            </Tooltip>
          ) : (
            extractContent(row?.description)
          )}
        </span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Action</span>,
      align: 'center',
      key: 'action',
      render: (data, row) => {
        return (
          <>
            <Button
              type='primary'
              size='small'
              className='th-br-4'
              onClick={() => handleOpenPermissionDrawer({ rowId: row?.id })}
            >
              Assign Permissions
            </Button>
            <EditOutlined
              title='Update'
              style={{
                fontSize: 20,
                margin: 10,
                cursor: 'pointer',
                color: '#1B4CCB',
              }}
              onClick={() =>
                handleOpenModal({
                  actionKey: 'edit',
                  editId: row?.id,
                  rowData: row,
                })
              }
            />
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() =>
                handleDelete({
                  delId: row?.id,
                })
              }
            >
              <DeleteOutlined
                title='Delete'
                style={{
                  fontSize: 20,
                  margin: 10,
                  cursor: 'pointer',
                  color: '#FF0000',
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];
  const drawerColumns = [
    // {
    //   title: '',
    //   align: 'center',
    //   render: (data, row) => <Checkbox disabled checked={row?.is_exist} />,
    // },
    {
      title: <span className='th-white th-16 th-fw-700'>Sl No.</span>,
      align: 'center',
      render: (data, row, index) => (
        <span className='th-black-1 th-16'>{(page - 1) * pageSize + index + 1}.</span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Class Name</span>,
      align: 'center',
      render: (data, row, index) => (
        <span className='th-black-1 th-16'>{row?.class_name}</span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Class Description</span>,
      align: 'center',
      render: (data, row) => (
        <span className='th-black-1 th-16'>
          {' '}
          {extractContent(row?.class_description).length > 30 ? (
            <Tooltip
              autoAdjustOverflow='false'
              placement='bottomLeft'
              title={extractContent(row?.class_description)}
              overlayStyle={{ maxWidth: '30%', minWidth: '20%' }}
            >
              {extractContent(row?.class_description).substring(0, 30) + '...'}
            </Tooltip>
          ) : (
            extractContent(row?.class_description)
          )}
        </span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Method</span>,
      align: 'center',
      render: (data, row, index) => (
        <span className='th-black-1 th-16'>{row?.method}</span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Status</span>,
      align: 'center',
      render: (data, row, index) => (
        <span className='th-black-1 th-16'>
          {row?.is_exists ? (
            <CheckCircleFilled style={{ color: 'green' }} />
          ) : (
            <CloseCircleFilled style={{ color: 'red' }} />
          )}
        </span>
      ),
    },
  ];
  const noDataLocale = {
    emptyText: (
      <div className='d-flex justify-content-center mt-5 th-grey'>
        <Empty
          description={
            <div>
              No data found. <br />
              Please try again.
            </div>
          }
        />
      </div>
    ),
  };
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    const ids = selectedRows.map((each) => each?.id);
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(ids);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: false,
      checked: selectedRowKeys.includes(record.id),
    }),
  };
  return (
    <>
      <div className='row py-3'>
        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item className='th-grey th-16'>Permission Class</Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>Level Mapping</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
          <div className='th-bg-white th-br-5 py-3 px-2 shadow-sm'>
            <div className='row justify-content-between'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 mb-2'>
                <Input
                  placeholder='Search Level Description'
                  suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                  className='w-100 text-left th-black-1 th-br-4'
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  allowClear
                />
              </div>
              <div className='col-lg-2 col-md-3 col-sm-3 col-12'>
                <Button
                  type='primary'
                  icon={<PlusCircleOutlined />}
                  onClick={() =>
                    handleOpenModal({
                      actionKey: 'create',
                    })
                  }
                  className='btn-block th-br-4'
                >
                  Create Level Permissions
                </Button>
              </div>
            </div>
            <div className='mt-2'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                <Table
                  className='th-table'
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'th-bg-grey' : 'th-bg-white'
                  }
                  loading={loading || tableLoading}
                  columns={columns}
                  rowKey={(record) => record?.id}
                  dataSource={tableData?.results}
                  pagination={false}
                  locale={noDataLocale}
                  scroll={{
                    x: window.innerWidth > 400 ? '100%' : 'max-content',
                  }}
                />
              </div>
              <div className='d-flex justify-content-center py-2'>
                <Pagination
                  current={currentPage}
                  pageSize={15}
                  showSizeChanger={false}
                  onChange={(page) => {
                    setCurrentPage(page);
                  }}
                  total={tableData?.count}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={openModal}
        title={editId ? 'Update Mapping' : 'Create Mapping'}
        onCancel={handleCloseModal}
        footer={[
          <Row justify='space-around'>
            <Col>
              <Button type='default' onClick={handleCloseModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type='primary'
                icon={
                  modalLoading ? (
                    <SyncOutlined spin />
                  ) : editId ? (
                    <EditOutlined />
                  ) : (
                    <PlusCircleOutlined />
                  )
                }
                className='btn-block th-br-4'
                form='formRef'
                htmlType='submit'
              >
                {editId ? 'Update' : 'Add'}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        {modalLoading ? (
          <div className='d-flex justify-content-center align-items-center'>
            <Spin tip='Hold on! Great things take time!' size='large' />
          </div>
        ) : (
          <>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>
              <Form
                id='formRef'
                form={formRef}
                layout='vertical'
                onFinish={editId ? handleEdit : handleCreate}
              >
                <Form.Item
                  name='level_id'
                  label='Select User Level'
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showArrow={true}
                    suffixIcon={<DownOutlined className='th-grey' />}
                    placeholder='Select User Level*'
                    showSearch
                    disabled={editId}
                    optionFilterProp='children'
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    dropdownMatchSelectWidth={false}
                    className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                  >
                    {userLevelOptions}
                  </Select>
                </Form.Item>
                <Form.Item
                  name='level_description'
                  label='Level Description'
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field',
                    },
                  ]}
                >
                  <TextArea
                    placeholder='Enter Level Description*'
                    className='w-100 text-left th-black-1 th-br-4'
                    allowClear
                    showCount
                    maxLength={250}
                    style={{ marginBottom: '20px' }}
                  />
                </Form.Item>
              </Form>
            </div>
          </>
        )}
      </Modal>
      <Drawer
        visible={drawerOPen}
        title='Update Mapping'
        onCancel={handleClosePermissionDrawer}
        footer={[
          <Row justify='space-around'>
            <Col>
              <Button type='default' onClick={handleClosePermissionDrawer}>
                Cancel
              </Button>
            </Col>
            {editMode && (
              <Col>
                <Button type='primary' onClick={handleEditPermission}>
                  Submit
                </Button>
              </Col>
            )}
          </Row>,
        ]}
        width='90%'
      >
        {modalLoading ? (
          <div className='d-flex justify-content-center align-items-center'>
            <Spin tip='Hold on! Great things take time!' size='large' />
          </div>
        ) : (
          <>
            <div className='mt-2'>
              <div className='row justify-content-between'>
                <div className='mb-2'>
                  <Input
                    placeholder='Search Level Description'
                    suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    className='w-100 text-left th-black-1 th-br-4'
                    onChange={(e) => setDrawerSearch(e.target.value)}
                    value={drawerSearch}
                    disabled={editMode}
                    allowClear
                  />
                </div>
                <div className=''>
                  <Button
                    type={!editMode ? 'primary' : 'default'}
                    onClick={() => setEditMode(!editMode)}
                    className=''
                  >
                    {editMode ? 'Cancel' : 'Edit'}
                  </Button>
                </div>
              </div>
              <div className='mt-2'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                  <Table
                    className='th-table'
                    rowSelection={editMode && rowSelection}
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? 'th-bg-grey' : 'th-bg-white'
                    }
                    loading={modalLoading}
                    columns={drawerColumns}
                    rowKey={(record) => record?.id}
                    dataSource={drawerTableData?.results}
                    pagination={false}
                    locale={noDataLocale}
                    scroll={{
                      x: window.innerWidth > 400 ? '100%' : 'max-content',
                    }}
                  />
                </div>
                <div className='d-flex justify-content-center py-2'>
                  <Pagination
                    disabled={editMode}
                    current={page}
                    pageSize={15}
                    showSizeChanger={false}
                    onChange={(page) => {
                      setPage(page);
                    }}
                    total={drawerTableData?.count}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default LevelMapping;
