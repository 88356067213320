import React from 'react';
import { useSelector } from 'react-redux';
import Filters from './filters';
import CreateQuestionPaper from '../../assessment-central/create-question-paper/index';

const QuestionPaperConfig = () => {
  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );
  const systemConfig = useSelector((state) => state.commonFilterReducer?.systemConfig);
  const showNewAsses = systemConfig?.asmt_enhancement.includes(
    String(selectedBranch?.branch?.id)
  );
  return <>{showNewAsses ? <Filters /> : <CreateQuestionPaper />}</>;
};

export default QuestionPaperConfig;
